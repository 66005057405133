exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-galaxy-vision-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/apps/galaxy-vision.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-galaxy-vision-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-refraction-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/apps/refraction.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-apps-refraction-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-about-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/about.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-about-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-index-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-privacy-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-privacy-mdx" */),
  "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-terms-mdx": () => import("./../../../src/pages/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/content/pages/terms.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-slug-js-content-file-path-content-pages-terms-mdx" */),
  "component---src-pages-smile-js": () => import("./../../../src/pages/smile.js" /* webpackChunkName: "component---src-pages-smile-js" */)
}

